/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
export function phoneDisplaySpecial(profile, key = 'mainPhone') {
  if (!profile[key]) {
   return null;
  }
  let num = profile[key];
    num = num.replace('+1', '');
    num = num.substring(0,3) + "-" + num.substring(3,6) + "-" + num.substring(6,10);
  return `${num}`;
}